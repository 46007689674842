<template>
  <div>
    <v-app-bar
      id="home-app-bar"
      app
      elevation="1"
      elevate-on-scroll
      height="80"
    >
      <!-- <base-img
        :src="require('@/assets/paydone/check-dark.png')"
        class="mr-3 hidden-xs-only"
        contain
        max-width="46"
        width="100%"
      /> -->

      <base-img
        :src="require(`@/assets/paydone/paydone-${$vuetify.theme.isDark ? 'dark' : 'light'}.png`)"
        contain
        max-width="200"
        width="100%"
      />

      <v-spacer />

      <div>
        <v-tabs
          class="hidden-sm-and-down"
          optional
          background-color="transparent"
        >
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="item.to"
            :exact="item.name === 'Home'"
            :ripple="false"
            class="font-weight-bold"
            active-class="active-tab"
            min-width="96"
            text
          >
            {{ item.label }}
          </v-tab>
          <v-tab>
            <v-img
              :src="require(`@/assets/paydone/${$i18n.locale == 'fr' ? 'en.jpeg' : 'fr.png'}`)"
              width="45px"
              max-width="45px"
              height="25px"
              max-height="45px"
              :ripple="false"
              @click.prevent="$i18n.locale = ($i18n.locale== 'fr' ? 'en' : 'fr')"
            />
          </v-tab>
          <!-- <v-btn width="45px">
            {{ $i18n.locale == 'fr' ? '🇪🇬' : '🇫🇷' }}
          </v-btn> -->
        </v-tabs>
      </div>

      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="drawer = !drawer"
      />
    </v-app-bar>

    <home-drawer
      v-model="drawer"
      :items="items"
    />
  </div>
</template>

<script>
  export default {
    name: 'HomeAppBar',

    components: {
      HomeDrawer: () => import('./Drawer'),
    },

    data: () => ({
      drawer: null,
    }),
    computed: {
      items () {
        return [
          { name: 'Home', label: this.$t('layout.home.appbar.home'), to: '/' },
          { name: 'About', label: this.$t('layout.home.appbar.solution'), to: 'about' },
          { name: 'Pricing', label: this.$t('layout.home.appbar.pricing'), to: 'pricing-plans' },
          { name: 'News', label: this.$t('layout.home.appbar.developper'), to: 'devs' },
          { name: 'Contact', label: this.$t('layout.home.appbar.contact'), to: 'contact-us' },
          { name: 'customer', label: this.$t('layout.home.appbar.customer'), to: 'customer' },
        ]
      },
    },
  }
</script>

<style lang="sass">
  #home-app-bar
    .v-tabs-slider
      max-width: 24px
      margin: 0 auto
      color: #0e979c

    .v-tab
      &::before
        display: none
    .active-tab
      color : #0e979c
</style>
